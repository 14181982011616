import React, { useState, useEffect, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, TextField, Paper,Button, CircularProgress, Input, Radio, RadioGroup, FormControlLabel, FormControl, Card, CardMedia, CardContent, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { Navigate, useParams, useLocation } from 'react-router-dom';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import ScienceChat from './ScienceChat';
import QuizRecap from './QuizRecap';
import { useAuth } from '../contexts/AuthContext';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const ContentWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: 'calc(100vh - 64px)', // Subtract AppBar height
  marginTop: 64, // AppBar height
}));

const LeftSection = styled(Box)(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
}));

const VideoSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const VideoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(1, 0),
}));

const VideoCard = styled(Card)(({ theme }) => ({
  width: 280,
  marginRight: theme.spacing(2),
  display: 'inline-block',
  verticalAlign: 'top',
  position: 'relative',
  cursor: 'pointer',
  '&:hover .playIcon': {
    opacity: 1,
  },
}));

const PlayIconOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '140px', // Match the height of CardMedia
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.3)',
  opacity: 0,
  transition: 'opacity 0.3s',
}));

const QuizSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const ChatSection = styled(Paper)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  height: 'calc(100vh - 140px)', // Adjust this value as needed
  maxHeight: 'calc(100vh - 140px)', // Ensure it doesn't exceed the viewport height
}));

const ChatContent = styled(Box)(({ theme }) => ({
  flex: 1,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column-reverse', // Change this line
  scrollBehavior: 'smooth', // Add this line
}));

const LoadingWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  minHeight: '200px',
}));

const VideoTitle = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '1.2em',
  height: '2.4em', // 2 lines * 1.2em line-height
}));

const QUIZ_FUNCTIONS_URL = 'https://us-central1-my-ai-speakign.cloudfunctions.net/checkQuizAnswer';
const SEARCH_YOUTUBE_URL = 'https://us-central1-adaptive-ai-learning.cloudfunctions.net/searchYoutube';

function AdaptiveScience() {
  const { user, loading } = useAuth();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [quizHistory, setQuizHistory] = useState([]);
  const [score, setScore] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [quizCompleted, setQuizCompleted] = useState(false);
  const [topic, setTopic] = useState('');
  const [videos, setVideos] = useState([]);
  const [language, setLanguage] = useState(null); // Default to English
  const [quizRecap, setQuizRecap] = useState(null);

  useEffect(() => {
    const initialTopic = location.state?.initialMessage || 'General Science';
    setTopic(initialTopic);
    // Get the language from localStorage or use a default value
    const storedLanguage = localStorage.getItem('userLanguage') || 'en';
    setLanguage(storedLanguage);
  }, [location]);

  useEffect(() => {
    if (user && topic) {
      fetchNextQuestion();
      fetchYoutubeVideos();
    }
  }, [user, topic, language]);

  const fetchYoutubeVideos = async () => {
    setIsVideoLoading(true);
    try {
      const response = await fetch(SEARCH_YOUTUBE_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          query: topic,
          language: language // Include the language in the payload
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      setVideos(data.videos.slice(0, 3)); // Take the first 3 videos
    } catch (error) {
      console.error('Error fetching YouTube videos:', error);
    } finally {
      setIsVideoLoading(false);
    }
  };

  const fetchNextQuestion = useCallback(async () => {
    if (!user || !topic) return;

    setIsLoading(true);
    try {
      const response = await fetch(QUIZ_FUNCTIONS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          action: 'nextQuestion', 
          quizHistory,
          userId: user.uid,
          topicId: topic,
          language: language // Include the language in the payload
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setCurrentQuestion(result);
    } catch (error) {
      console.error('Error fetching next question:', error);
    } finally {
      setIsLoading(false);
    }
  }, [quizHistory, user, topic, language]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleSubmitAnswer = async (event) => {
    event.preventDefault(); // Prevent default form submission
    if (!selectedOption || !currentQuestion) return;

    setIsLoading(true);
    try {
      const response = await fetch(QUIZ_FUNCTIONS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'checkAnswer',
          userId: user.uid,
          topicId: topic,
          currentQuestion: currentQuestion.question,
          userAnswer: selectedOption,
          options: currentQuestion.options,
          language: language
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      
      setQuizHistory([...quizHistory, { 
        question: currentQuestion, 
        userAnswer: selectedOption, 
        correct: result.correct 
      }]);
      
      if (result.correct) {
        setScore(score + 1);
      }

      setSelectedOption('');
      setQuestionCount(questionCount + 1);

      if (questionCount + 1 >= 5) {
        setQuizCompleted(true);
        await recapLearningSession();
      } else {
        fetchNextQuestion();
      }
    } catch (error) {
      console.error('Error submitting answer:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const recapLearningSession = async () => {
    try {
      const response = await fetch(QUIZ_FUNCTIONS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          action: 'recapLearningSession',
          userId: user.uid,
          topicId: topic,
          quizHistory: quizHistory,
          score: score,
          language: language
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setQuizRecap(result);
    } catch (error) {
      console.error('Error recapping learning session:', error);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleVideoClick = (videoId) => {
    window.open(`https://www.youtube.com/watch?v=${videoId}`, '_blank');
  };

  const prepareChatData = useCallback(() => {
    return {
      action: 'chat',
      userId: user?.uid || 'anonymous',
      topicId: topic,
      language: language
    };
  }, [user, topic, language]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <ContentWrapper>
          <LeftSection>
            <VideoSection>
              <Typography variant="h6" gutterBottom>Related Videos</Typography>
              {isVideoLoading ? (
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              ) : (
                <VideoContainer>
                  {videos.map((video) => (
                    <VideoCard 
                      key={video.videoId} 
                      onClick={() => handleVideoClick(video.videoId)}
                    >
                      <CardMedia
                        component="img"
                        height="140"
                        image={video.thumbnailUrl}
                        alt={video.title}
                      />
                      <PlayIconOverlay className="playIcon">
                        <IconButton 
                          aria-label="play"
                          sx={{ color: 'white' }}
                        >
                          <PlayArrowIcon fontSize="large" />
                        </IconButton>
                      </PlayIconOverlay>
                      <CardContent>
                        <VideoTitle variant="subtitle1">
                          {video.title}
                        </VideoTitle>
                      </CardContent>
                    </VideoCard>
                  ))}
                </VideoContainer>
              )}
            </VideoSection>
            <QuizSection>
              <Typography variant="h6" gutterBottom>Adaptive Quiz</Typography>
              {isLoading ? (
                <LoadingWrapper>
                  <CircularProgress />
                </LoadingWrapper>
              ) : !quizCompleted ? (
                <form onSubmit={handleSubmitAnswer}>
                  <Typography variant="subtitle1" gutterBottom>Question {questionCount + 1} of 5</Typography>
                  {currentQuestion && (
                    <>
                      <Typography variant="body1" gutterBottom>{currentQuestion.question}</Typography>
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="quiz"
                          name="quiz"
                          value={selectedOption}
                          onChange={handleOptionChange}
                        >
                          {currentQuestion.options && currentQuestion.options.map((option, index) => (
                            <FormControlLabel
                              key={index}
                              value={option}
                              control={<Radio />}
                              label={option}
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                      <Box sx={{ mt: 2 }}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="primary"
                          disabled={!selectedOption || isLoading}
                          startIcon={isLoading ? <CircularProgress size={24} /> : null}
                          fullWidth
                        >
                          Submit Answer
                        </Button>
                      </Box>
                    </>
                  )}
                </form>
              ) : (
                quizRecap && <QuizRecap recap={quizRecap} />
              )}
            </QuizSection>
          </LeftSection>
          <ChatSection>
            <Typography variant="h6" gutterBottom>Science AI Supporter</Typography>
            <ChatContent>
              <ScienceChat 
                chatData={prepareChatData()}
              />
            </ChatContent>
          </ChatSection>
        </ContentWrapper>
      </Main>
    </Box>
  );
}

export default AdaptiveScience;