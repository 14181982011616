import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate, Navigate } from 'react-router-dom';
import { Typography, List, ListItem, ListItemIcon, ListItemText, CircularProgress, Paper, Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useAuth } from '../contexts/AuthContext';
import ScienceIcon from '@mui/icons-material/Science';
import ComputerIcon from '@mui/icons-material/Computer';
import TopBar from './TopBar';
import Sidebar from './Sidebar';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const LearningPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: theme.spacing(3),
}));

const PathwayList = styled(List)(({ theme }) => ({
  position: 'relative',
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '16px',
    top: 0,
    bottom: 0,
    width: '2px',
    background: theme.palette.primary.main,
    zIndex: 1,
  },
}));

const PathwayItem = styled(ListItem)(({ theme }) => ({
  position: 'relative',
  zIndex: 2,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    left: '12px',
    top: '50%',
    width: '10px',
    height: '10px',
    background: theme.palette.background.paper,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '50%',
    transform: 'translateY(-50%)',
    zIndex: 2,
  },
}));

const topics = {
  k12: {
    "Science": [
      "The human body and its systems",
      "Plants and animals: Life cycles and habitats",
      "Weather and seasons",
      "Simple machines and how they work",
      "Cells and genetics",
      "Chemical reactions and elements",
      "Forces and motion",
      "Earth's history and geology",
      "Biology: Ecology, evolution, human anatomy",
      "Chemistry: Organic chemistry, chemical bonding, stoichiometry",
      "Physics: Mechanics, electricity, magnetism",
      "Environmental science and sustainability",
    ],
    "Technology": [
      "Digital literacy and online safety",
      "Basic computer skills (typing, file management)",
      "Introduction to robotics and coding",
      "Basic coding concepts (using visual programming)",
      "Introduction to coding languages (Scratch, Python)",
      "3D printing and design",
      "Video game design and development",
      "App development for mobile devices",
      "Web development (HTML, CSS, JavaScript)",
      "Computer science: Programming fundamentals",
      "Introduction to data science and analytics",
      "Emerging technologies (AI, machine learning, blockchain)",
    ],
  },
  lifelong: {
    "Science": [
      "Nutrition and healthy aging",
      "Understanding common diseases",
      "The science of sleep and stress management",
      "Climate change and its impacts",
      "Renewable energy sources",
      "Sustainable living practices",
      "Cosmology and astronomy",
      "The science of cooking",
      "The human brain and psychology",
    ],
    "Technology": [
      "Using smartphones and tablets effectively",
      "Online privacy and security",
      "Social media and online communication",
      "Data analysis and visualization",
      "Project management software",
      "Digital marketing and social media strategy",
      "Photography and video editing",
      "Graphic design and web design",
      "Online learning platforms and resources",
    ],
  },
};

const TRANSLATION_FUNCTION_URL = 'https://us-central1-my-ai-speakign.cloudfunctions.net/translateText';

async function translateTexts(texts, targetLanguage, idToken) {
  const response = await fetch(TRANSLATION_FUNCTION_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    },
    body: JSON.stringify({ texts, targetLanguage }),
  });

  if (!response.ok) {
    throw new Error('Translation failed');
  }

  const data = await response.json();
  return data.translations;
}

function Pathway() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const profile = searchParams.get('profile') || 'k12';
  const lang = searchParams.get('lang') || sessionStorage.getItem('language') || 'en';
  const [translatedTopics, setTranslatedTopics] = useState(null);
  const [translatedTitle, setTranslatedTitle] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const { user, loading } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    async function translateContent() {
      if (!user) {
        setIsLoading(false);
        return;
      }

      if (lang === 'en') {
        setTranslatedTopics(topics[profile]);
        setTranslatedTitle(`Learning Pathway for ${profile === 'k12' ? 'K-12 and Early Adults' : 'Lifelong Learners'}`);
        setIsLoading(false);
        return;
      }

      try {
        const idToken = await user.getIdToken();
        const titleToTranslate = `Learning Pathway for ${profile === 'k12' ? 'K-12 and Early Adults' : 'Lifelong Learners'}`;
        const flattenedTopics = [
          titleToTranslate,
          ...Object.entries(topics[profile]).flatMap(([category, topicList]) =>
            [category, ...topicList]
          )
        ];
        
        const translatedTexts = await translateTexts(flattenedTopics, lang, idToken);
        
        setTranslatedTitle(translatedTexts[0].translatedText);

        const translatedTopicsObj = {};
        let translatedIndex = 1; // Start from 1 because 0 is the title
        for (const category of Object.keys(topics[profile])) {
          const translatedCategory = translatedTexts[translatedIndex++].translatedText;
          translatedTopicsObj[translatedCategory] = topics[profile][category].map(() => 
            translatedTexts[translatedIndex++].translatedText
          );
        }
        setTranslatedTopics(translatedTopicsObj);
      } catch (error) {
        console.error('Translation error:', error);
        setTranslatedTopics(topics[profile]); // Fallback to original content
        setTranslatedTitle(`Learning Pathway for ${profile === 'k12' ? 'K-12 and Early Adults' : 'Lifelong Learners'}`);
      } finally {
        setIsLoading(false);
      }
    }

    translateContent();
  }, [profile, lang, user, loading]);

  const handleTopicClick = (topic) => {
    navigate('/science', { state: { initialMessage: topic } });
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  const displayTopics = translatedTopics || topics[profile];

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <LearningPaper elevation={3}>
          <Typography variant="h4" gutterBottom>
            {translatedTitle}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Language: {lang.toUpperCase()}
          </Typography>
          <Grid container spacing={4}>
            {Object.entries(displayTopics).map(([category, topicList]) => (
              <Grid item xs={12} md={6} key={category}>
                <Typography variant="h5" gutterBottom>{category}</Typography>
                <PathwayList>
                  {topicList.map((topic, index) => (
                    <PathwayItem key={index} onClick={() => handleTopicClick(topic)}>
                      <ListItemIcon>
                        {category === "Science" ? <ScienceIcon color="primary" /> : <ComputerIcon color="primary" />}
                      </ListItemIcon>
                      <ListItemText primary={topic} />
                    </PathwayItem>
                  ))}
                </PathwayList>
              </Grid>
            ))}
          </Grid>
        </LearningPaper>
      </Main>
    </Box>
  );
}

export default Pathway;