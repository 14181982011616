// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyAWJxTlSxhJjfFn_-gku2v2s-4slYdBXXw",
    authDomain: "my-ai-speakign.firebaseapp.com",
    projectId: "my-ai-speakign",
    storageBucket: "my-ai-speakign.appspot.com",
    messagingSenderId: "753846325892",
    appId: "1:753846325892:web:6b4151fb69936edeb368d1",
    measurementId: "G-KRP0C37K8Z"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);