import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const FlagContainer = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  transition: 'transform 0.1s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

const FlagImage = styled('img')(({ theme }) => ({
  width: '100%',
  maxWidth: '100px',
  height: 'auto',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  boxShadow: `0 0 10px ${theme.palette.action.hover}`,
  transition: 'box-shadow 0.3s ease-in-out',
  marginBottom: theme.spacing(1),
  '&:hover': {
    boxShadow: `0 0 15px ${theme.palette.primary.main}`,
  },
}));

const CountryName = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '0.9rem',
}));

const languages = [
  { code: 'ar', name: 'Arabic', flag: 'sa' },
  { code: 'bn', name: 'Bengali', flag: 'bd' },
  { code: 'bg', name: 'Bulgarian', flag: 'bg' },
  { code: 'zh', name: 'Chinese', flag: 'cn' },
  { code: 'hr', name: 'Croatian', flag: 'hr' },
  { code: 'cs', name: 'Czech', flag: 'cz' },
  { code: 'da', name: 'Danish', flag: 'dk' },
  { code: 'nl', name: 'Dutch', flag: 'nl' },
  { code: 'en', name: 'English', flag: 'gb' },
  { code: 'et', name: 'Estonian', flag: 'ee' },
  { code: 'fi', name: 'Finnish', flag: 'fi' },
  { code: 'fr', name: 'French', flag: 'fr' },
  { code: 'de', name: 'German', flag: 'de' },
  { code: 'el', name: 'Greek', flag: 'gr' },
  { code: 'iw', name: 'Hebrew', flag: 'il' },
  { code: 'hi', name: 'Hindi', flag: 'in' },
  { code: 'hu', name: 'Hungarian', flag: 'hu' },
  { code: 'id', name: 'Indonesian', flag: 'id' },
  { code: 'it', name: 'Italian', flag: 'it' },
  { code: 'ja', name: 'Japanese', flag: 'jp' },
  { code: 'ko', name: 'Korean', flag: 'kr' },
  { code: 'lv', name: 'Latvian', flag: 'lv' },
  { code: 'lt', name: 'Lithuanian', flag: 'lt' },
  { code: 'no', name: 'Norwegian', flag: 'no' },
  { code: 'pl', name: 'Polish', flag: 'pl' },
  { code: 'pt', name: 'Portuguese', flag: 'pt' },
  { code: 'ro', name: 'Romanian', flag: 'ro' },
  { code: 'ru', name: 'Russian', flag: 'ru' },
  { code: 'sr', name: 'Serbian', flag: 'rs' },
  { code: 'sk', name: 'Slovak', flag: 'sk' },
  { code: 'sl', name: 'Slovenian', flag: 'si' },
  { code: 'es', name: 'Spanish', flag: 'es' },
  { code: 'sw', name: 'Swahili', flag: 'tz' },
  { code: 'sv', name: 'Swedish', flag: 'se' },
  { code: 'th', name: 'Thai', flag: 'th' },
  { code: 'tr', name: 'Turkish', flag: 'tr' },
  { code: 'uk', name: 'Ukrainian', flag: 'ua' },
  { code: 'vi', name: 'Vietnamese', flag: 'vn' },
];

function Language() {
  const navigate = useNavigate();
  const { user, loading: authLoading } = useAuth();
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!authLoading) {
      if (!user) {
        navigate('/login');
      } else {
        setIsLoading(false);
      }
    }
  }, [user, authLoading, navigate]);

  const handleFlagClick = async (languageCode) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, { language: languageCode }, { merge: true });
      
      // Store in local storage
      localStorage.setItem('userLanguage', languageCode);

      // Navigate to the profile page after successful
      navigate(`/profile?lang=${languageCode}`);
    } catch (error) {
      console.error('Error updating user language:', error);
      setError('Failed to update language. Please try again later.');
      // Navigate anyway, but the language won't be saved
      navigate(`/profile?lang=${languageCode}`);
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setError(null);
  };

  if (authLoading || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return null; // The useEffect will handle navigation to login
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <Box sx={{ flexGrow: 1, mt: 8, p: 3 }}>
          <Grid container spacing={2}>
            {languages.map((language) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl={12/7} key={language.code}>
                <FlagContainer
                  onClick={() => handleFlagClick(language.code)}
                >
                  <FlagImage
                    src={`https://flagcdn.com/w80/${language.flag}.png`}
                    alt={`${language.name} flag`}
                    title={language.name}
                  />
                  <CountryName variant="body2">
                    {language.name}
                  </CountryName>
                </FlagContainer>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Main>
      <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
        <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
          {error}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default Language;