import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import SendIcon from '@mui/icons-material/Send';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Navigate } from 'react-router-dom';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { useAuth } from '../contexts/AuthContext';

const FUNCTIONS_URL = 'https://us-central1-my-ai-speakign.cloudfunctions.net/processImage';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const Input = styled('input')({
  display: 'none',
});

function Writing() {
  const { user, loading } = useAuth();
  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [response, setResponse] = useState('');

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFiles(files.map(file => ({
      file,
      preview: URL.createObjectURL(file)
    })));
  };

  const handleSubmit = async () => {
    if (selectedFiles.length === 0) return;

    setIsUploading(true);
    setResponse('');

    try {
      // Convert files to base64
      const filesBase64 = await Promise.all(
        selectedFiles.map(({ file }) => new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
          reader.readAsDataURL(file);
        }))
      );

      // Call the Cloud Function to handle file upload and processing
      const response = await fetch(FUNCTIONS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ images: filesBase64 }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      setResponse(result.markdown || 'No markdown content received.');

    } catch (error) {
      console.error('Error processing files:', error);
      setResponse('Error processing images. Please try again.');
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <Paper style={{ padding: 20, marginTop: 64 }}>
          <Typography variant="h5" gutterBottom>Assess Your Creative Writing</Typography>
          <label htmlFor="contained-button-file">
            <Input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              component="span"
              startIcon={<CloudUploadIcon />}
            >
              Upload Images
            </Button>
          </label>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            disabled={selectedFiles.length === 0 || isUploading}
            startIcon={isUploading ? <CircularProgress size={24} /> : <SendIcon />}
            style={{ marginLeft: 10 }}
          >
            {isUploading ? 'Processing...' : 'Submit'}
          </Button>
          
          {selectedFiles.length > 0 && (
            <Grid container spacing={2} style={{ marginTop: 20 }}>
              {selectedFiles.map((file, index) => (
                <Grid item xs={3} key={index}>
                  <Card>
                    <CardMedia
                      component="img"
                      height="140"
                      image={file.preview}
                      alt={`Preview ${index}`}
                    />
                    <CardContent>
                      <Typography variant="body2" color="text.secondary">
                        {file.file.name}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
          
          {response && (
            <Paper style={{ marginTop: 20, padding: 20 }}>
              <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                components={{
                  table: ({ node, ...props }) => (
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table" {...props} />
                    </TableContainer>
                  ),
                  thead: ({ node, ...props }) => <TableHead {...props} />,
                  tbody: ({ node, ...props }) => <TableBody {...props} />,
                  tr: ({ node, ...props }) => <TableRow {...props} />,
                  th: ({ node, ...props }) => (
                    <TableCell
                      {...props}
                      sx={{
                        fontWeight: 'bold',
                        backgroundColor: '#f5f5f5',
                        borderBottom: '2px solid #ddd'
                      }}
                    />
                  ),
                  td: ({ node, ...props }) => <TableCell {...props} />,
                }}
              >
                {response}
              </ReactMarkdown>
            </Paper>
          )}
        </Paper>
      </Main>
    </Box>
  );
}

export default Writing;