import React, { useState, useEffect, useCallback, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import SendIcon from '@mui/icons-material/Send';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import StopIcon from '@mui/icons-material/Stop';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import { Navigate } from 'react-router-dom';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { useAuth } from '../contexts/AuthContext';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import axios from 'axios';

const CHAT_FUNCTIONS_URL = 'https://us-central1-my-ai-speakign.cloudfunctions.net/speakWithAI';
const PUNCTUATE_FUNCTIONS_URL = 'https://us-central1-my-ai-speakign.cloudfunctions.net/punctuateText';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const DialogContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginTop: 64, // AppBar height
  height: 'calc(100vh - 64px - 48px - 48px)', // 100vh - AppBar height - 2 * Main padding
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden', // Prevent scrolling on the outer container
}));

function Speaking() {
  const { user, loading } = useAuth();
  const [open, setOpen] = useState(false);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [conversation, setConversation] = useState([]);
  const [isProcessingSpeech, setIsProcessingSpeech] = useState(false);
  const lastTranscriptRef = useRef('');
  const [speakingMessageId, setSpeakingMessageId] = useState(null);
  const initialGreetingRef = useRef(null);

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition
  } = useSpeechRecognition();

  const speakMessage = useCallback((message, messageId) => {
    if ('speechSynthesis' in window) {
      setSpeakingMessageId(messageId);
      const utterance = new SpeechSynthesisUtterance(message);
      utterance.onend = () => {
        setSpeakingMessageId(null);
      };
      window.speechSynthesis.speak(utterance);
    } else {
      console.log("Speech synthesis not supported");
    }
  }, []);

  const stopSpeaking = useCallback(() => {
    if ('speechSynthesis' in window) {
      window.speechSynthesis.cancel();
      setSpeakingMessageId(null);
    }
  }, []);

  const toggleSpeaking = useCallback((message, messageId) => {
    if (speakingMessageId === messageId) {
      stopSpeaking();
    } else {
      speakMessage(message, messageId);
    }
  }, [speakingMessageId, stopSpeaking, speakMessage]);

  useEffect(() => {
    const initialGreeting = `Hello! I'm your AI assistant. What topic would you like to discuss today? Here are some suggestions:

1. Technology and AI
2. Science and Space Exploration
3. Environmental Issues and Sustainability
4. Health and Wellness
5. Arts and Culture
6. Current Events and Global Affairs
7. Personal Development and Productivity
8. Sports and Fitness

Feel free to choose one of these topics or suggest your own!`;

    initialGreetingRef.current = initialGreeting;

    const greetingMessage = {
      id: 'greeting',
      role: 'assistant',
      content: initialGreeting,
    };
    setConversation([greetingMessage]);
  }, []);

  useEffect(() => {
    if (transcript) {
      setInput(transcript);
    }
  }, [transcript]);

  const toggleListening = () => {
    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      resetTranscript();
      setInput('');
      SpeechRecognition.startListening({ continuous: true });
    }
  };

  const handleSubmit = useCallback(async (submittedText = input) => {
    if (!submittedText.trim() || isLoading) return;

    setIsLoading(true);
    resetTranscript(); // Reset the transcript after submitting
    const userMessage = { id: Date.now(), role: 'user', content: submittedText };
    const updatedConversation = [...conversation, userMessage];
    setConversation(updatedConversation);

    try {
      const response = await fetch(CHAT_FUNCTIONS_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          message: submittedText,
          conversation: updatedConversation.map(msg => ({
            role: msg.role,
            content: msg.content
          }))
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();
      const aiMessage = { id: Date.now(), role: 'assistant', content: result.reply };
      setConversation(prev => [...prev, aiMessage]);
      
      // Auto-speak the AI message
      speakMessage(aiMessage.content, aiMessage.id);
    } catch (error) {
      console.error('Error processing message:', error);
      const errorMessage = { id: Date.now(), role: 'assistant', content: 'Sorry, there was an error processing your message.' };
      setConversation(prev => [...prev, errorMessage]);
      
      // Auto-speak the error message
      speakMessage(errorMessage.content, errorMessage.id);
    } finally {
      setIsLoading(false);
      setInput('');
    }
  }, [input, isLoading, speakMessage, resetTranscript, conversation]);

  const handlePunctuateAndSubmit = useCallback(async (text) => {
    if (isProcessingSpeech || text === lastTranscriptRef.current) return;

    setIsProcessingSpeech(true);
    lastTranscriptRef.current = text;

    try {
      // Step 1: Punctuate the text
      const punctuateResponse = await axios.post(PUNCTUATE_FUNCTIONS_URL, { text });
      const punctuatedText = punctuateResponse.data.text;

      // Step 2: Submit the punctuated text to chatWithAI
      await handleSubmit(punctuatedText);
    } catch (error) {
      console.error('Error processing speech:', error);
      const errorMessage = { id: Date.now(), role: 'assistant', content: 'Sorry, there was an error processing your speech.' };
      setConversation(prev => [...prev, errorMessage]);
    } finally {
      setIsProcessingSpeech(false);
      resetTranscript();
    }
  }, [setConversation, resetTranscript, handleSubmit]);

  useEffect(() => {
    if (!listening && transcript && !isProcessingSpeech) {
      handlePunctuateAndSubmit(transcript);
    }
  }, [listening, transcript, handlePunctuateAndSubmit, isProcessingSpeech]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  if (!browserSupportsSpeechRecognition) {
    console.log("Browser doesn't support speech recognition.");
  }

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <DialogContent>
          <Typography variant="h5" gutterBottom>Speak with AI Examiner</Typography>
        
          <Box sx={{ flexGrow: 1, overflowY: 'auto', marginBottom: 2 }}>
            {conversation.map((message) => (
              <Paper key={message.id} sx={{ 
                padding: 1, 
                margin: '10px 0', 
                backgroundColor: message.role === 'user' ? '#e3f2fd' : '#f1f8e9',
                whiteSpace: 'pre-wrap',
                position: 'relative'
              }}>
                <Typography><strong>{message.role === 'user' ? 'You' : 'AI'}:</strong> {message.content}</Typography>
                {message.role === 'assistant' && (
                  <Box sx={{ position: 'absolute', top: 5, right: 5, display: 'flex', alignItems: 'center' }}>
                    {speakingMessageId === message.id && <Typography variant="caption" sx={{ marginRight: 1 }}>Speaking...</Typography>}
                    <IconButton 
                      onClick={() => toggleSpeaking(message.content, message.id)}
                    >
                      {speakingMessageId === message.id ? <StopIcon /> : <VolumeUpIcon />}
                    </IconButton>
                  </Box>
                )}
              </Paper>
            ))}
          </Box>
          <Box sx={{ display: 'flex' }}>
            <TextField
              fullWidth
              variant="outlined"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              placeholder={listening ? "Listening..." : "Type your message here or click the microphone to start speaking..."}
              disabled={isLoading}
              multiline
              maxRows={4}
            />
            <Button
              variant="contained"
              color={listening ? "secondary" : "primary"}
              onClick={toggleListening}
              disabled={!browserSupportsSpeechRecognition}
              startIcon={listening ? <MicOffIcon /> : <MicIcon />}
              sx={{ marginLeft: 1, marginRight: 1 }}
            >
              {listening ? 'Stop' : 'Start'}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleSubmit()}
              disabled={!input.trim() || isLoading}
              startIcon={isLoading ? <CircularProgress size={24} /> : <SendIcon />}
              sx={{ marginLeft: 1 }}
            >
              Send
            </Button>
          </Box>
        </DialogContent>
      </Main>
    </Box>
  );
}

export default Speaking;