import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Typography, Grid, Paper, List, ListItem, ListItemText, CircularProgress, Box } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import TopBar from './TopBar';
import Sidebar from './Sidebar';
import { getFirestore, doc, setDoc } from 'firebase/firestore';

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const ProfilePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.03)',
    boxShadow: theme.shadows[4],
    cursor: 'pointer',
  },
}));

const ProfileImage = styled('img')({
  width: '100%',
  height: 'auto',
  marginBottom: '1rem',
  borderRadius: '8px',
});

const CenteredTypography = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  marginBottom: theme.spacing(4),
}));

const originalContent = {
  title: 'Which learning journey resonates with you?',
  k12: {
    title: 'K-12 and Early Adults (Ages 6-24)',
    characteristics: [
      'Rapid cognitive development',
      'High adaptability to new technologies',
      'Strong influence from peer groups',
      'Need for interactive and engaging content',
      'Shorter attention spans',
      'Benefit from gamification in learning',
    ],
  },
  lifelong: {
    title: 'Lifelong Learners (Ages 25-60+)',
    characteristics: [
      'Self-motivated learners',
      'Bring rich life experiences to learning',
      'May face time constraints due to work/family',
      'Prefer practical, applicable knowledge',
      'Varied comfort levels with technology',
      'Value flexibility in learning schedules',
    ],
  },
  characteristicsTitle: 'Characteristics:',
};

const TRANSLATION_FUNCTION_URL = 'https://us-central1-my-ai-speakign.cloudfunctions.net/translateText';

async function translateTexts(texts, targetLanguage, idToken) {
  const response = await fetch(TRANSLATION_FUNCTION_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${idToken}`
    },
    body: JSON.stringify({ texts, targetLanguage }),
  });

  if (!response.ok) {
    throw new Error('Translation failed');
  }

  const data = await response.json();
  return data.translations;
}

function Profile() {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const [lang, setLang] = useState(searchParams.get('lang') || localStorage.getItem('userLanguage') || 'en');
  const [translatedContent, setTranslatedContent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { user, loading: authLoading } = useAuth();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    sessionStorage.setItem('language', lang);

    if (authLoading) return;

    if (!user) {
      navigate('/login');
      return;
    }

    async function translateContent() {
      if (lang === 'en') {
        setTranslatedContent(originalContent);
        setIsLoading(false);
        return;
      }

      try {
        const idToken = await user.getIdToken();

        const textsToTranslate = [
          originalContent.title,
          originalContent.k12.title,
          originalContent.lifelong.title,
          originalContent.characteristicsTitle,
          ...originalContent.k12.characteristics,
          ...originalContent.lifelong.characteristics
        ];

        const translatedTexts = await translateTexts(textsToTranslate, lang, idToken);

        setTranslatedContent({
          title: translatedTexts[0].translatedText,
          k12: {
            title: translatedTexts[1].translatedText,
            characteristics: translatedTexts.slice(4, 10).map(t => t.translatedText),
          },
          lifelong: {
            title: translatedTexts[2].translatedText,
            characteristics: translatedTexts.slice(10).map(t => t.translatedText),
          },
          characteristicsTitle: translatedTexts[3].translatedText,
        });
      } catch (error) {
        console.error('Translation error:', error);
        setTranslatedContent(originalContent); // Fallback to original content
      } finally {
        setIsLoading(false);
      }
    }

    translateContent();
  }, [lang, user, navigate, authLoading]);

  const handleProfileClick = async (profileType) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, { profile: profileType }, { merge: true });

      // Store in local storage
      localStorage.setItem('userProfile', profileType);
      
      // Navigate to the pathway page after successful Firestore update
      navigate(`/pathway?profile=${profileType}&lang=${lang}`);
    } catch (error) {
      console.error('Error updating user profile:', error);
      // You might want to show an error message to the user here
    }
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  if (authLoading || isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return null; // The useEffect will handle navigation to login
  }

  const t = translatedContent || originalContent;

  return (
    <Box sx={{ display: 'flex' }}>
      <TopBar open={open} handleDrawerOpen={handleDrawerOpen} user={user} />
      <Sidebar open={open} handleDrawerClose={handleDrawerClose} user={user} />
      <Main open={open}>
        <Box sx={{ maxWidth: '55%', margin: '0 auto', padding: '48px 16px' }}>
          <CenteredTypography variant="h4" gutterBottom>
            {t.title}
          </CenteredTypography>
          <Grid container spacing={4}>
            {Object.entries({
              k12: {
                title: t.k12.title,
                image: '/images/k12_learners.jpeg',
                characteristics: t.k12.characteristics,
              },
              lifelong: {
                title: t.lifelong.title,
                image: '/images/lifelong_learners.jpeg',
                characteristics: t.lifelong.characteristics,
              },
            }).map(([key, profile]) => (
              <Grid item xs={12} md={6} key={key}>
                <ProfilePaper elevation={3} onClick={() => handleProfileClick(key)}>
                  <Typography variant="h5" gutterBottom>
                    {profile.title}
                  </Typography>
                  <ProfileImage src={profile.image} alt={profile.title} />
                  <Typography variant="h6" gutterBottom>
                    {t.characteristicsTitle}
                  </Typography>
                  <List>
                    {profile.characteristics.map((characteristic, index) => (
                      <ListItem key={index}>
                        <ListItemText primary={characteristic} />
                      </ListItem>
                    ))}
                  </List>
                </ProfilePaper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Main>
    </Box>
  );
}

export default Profile;